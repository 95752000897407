<template>
  <ValidationProvider
    #default="validationContext"
    :ref="refName"
    :name="name"
    :vid="name"
    :rules="rules"
    tag="div">
    <b-form-group
      :label="label"
      :state="$getValidationState(validationContext)">
      <b-input-group class="input-group-merge">
        <b-form-input
          v-model="innerValue"
          :class="innerClass"
          v-bind="$attrs"
          :type="passwordFieldType"
          :state="$getValidationState(validationContext)" />
        <b-input-group-append is-text>
          <feather-icon
            :icon="passwordToggleIcon"
            class="cursor-pointer"
            @click="togglePasswordVisibility" />
        </b-input-group-append>
      </b-input-group>

      <b-form-invalid-feedback :state="$getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  mixins: [togglePasswordVisibility],
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object],
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    },
    innerClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    refName () {
      return `validation-provider-${this.name}`
    },
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  }
}
</script>

<style>

</style>

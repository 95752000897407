<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          User Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="UserDetailForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="user.code"
            name="code"
            label="User Code"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.firstName"
            name="FirstName"
            label="Firstname"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.lastName"
            name="LastName"
            label="Lastname"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.email"
            name="Email"
            label="Email"
            :rules="{ required: true, email: true }"
            trim />
          <TextFieldInput
            v-model="user.tel"
            name="Tel"
            label="Tel"
            :rules="{ required: true}"
            trim
          />
          <!-- <TextFieldInput
            v-model="user.username"
            name="Username"
            label="Username"
            :rules="{ required: true }"
            trim /> -->
          <!-- <PasswordInput
            v-model="user.password"
            name="Password"
            label="Password"
            trim /> -->
          <PasswordInput
            v-if="isChangePassword"
            v-model="user.password"
            name="newPassword"
            label="New Password"
            :rules="{
              required: true,
            }"
            trim />
          <PasswordInput
            v-if="isChangePassword"
            v-model="confirmPassword"
            name="Confirm Password"
            label="Confirm Password"
            :rules="{
              required: true,
              confirmed: 'newPassword'
            }"
            trim />
          <b-button
            class="my-1"
            :variant="isChangePassword ? 'outline-warning' : 'warning'"
            @click="handleChangePassword()"
          >
            {{ isChangePassword ? 'Cancel' : 'Change Password' }}
          </b-button>
          <!-- <PasswordInput
            v-model="user.password"
            name="Password"
            label="Password"
            trim /> -->
          <TextFieldInput
            v-model="user.licenseCode"
            name="License"
            label="License Code"
            trim />
          <SelectField
            v-model="user.userTeamId"
            :options="userTeam"
            :rules="{ required: true }"
            name="UserTeamId"
            form-label="User Team"
            label-option="name"
            return-value="id" />
          <SelectField
            v-model="user.role"
            :options="roleData"
            :rules="{ required: true }"
            name="Role"
            form-label="Role"
            label-option="label"
            return-value="value" />
          <StatusRadioButton :status.sync="user.status" />
          <b-row>
            <b-col>
              Created At : {{ user.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ user.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ user.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ user.updatedBy.username }}
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              @click="deleteItem ()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import PasswordInput from '@/components/Form/PasswordInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    PasswordInput,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialUser: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    },
    userTeam: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      roleData: [
        { label: 'Agent', value: 'agent' },
        { label: 'Management', value: 'management' },
        { label: 'Manager', value: 'manager' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'SuperAdmin', value: 'super admin' }
      ],
      confirmPassword: '',
      isChangePassword: false
    }
  },
  computed: {
    user: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return false
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    handleChangePassword () {
      this.isChangePassword = !this.isChangePassword
      this.user.password = ''
      this.confirmPassword = ''
    },
    onSubmit () {
      const payload = {
        code: this.user.code,
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        tel: this.user.tel,
        username: this.user.username,
        password: this.user.password,
        licenseCode: this.user.licenseCode,
        userTeamId: this.user.userTeamId,
        userGroupId: null,
        role: this.user.role,
        status: this.user.status
      }
      const isConfirmPassword = this.user.password === this.confirmPassword

      if (!this.isChangePassword || !this.user.password || !isConfirmPassword) {
        delete payload.password
      }

      if (this.userTeam && this.userTeam?.length > 0) {
        const findUserTeam = this.userTeam.filter((item) => item.id === this.user.userTeamId)
        if (findUserTeam && findUserTeam?.length > 0) {
          payload.userGroupId = findUserTeam[0].userGroupId
        }
      }
      this.$emit('update-user', payload)
      this.isChangePassword = false
      this.user.password = ''
      this.confirmPassword = ''
    },
    resetForm () {
      this.user = { ...this.initialUser }
      this.isChangePassword = false
      this.user.password = ''
      this.confirmPassword = ''
      this.$refs.UserDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.user.id,
        Name: `${this.user.firstName} ${this.user.lastName}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>

import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class UserProvider extends HttpRequest {
  findAll () {
    return this.get('/user')
  }

  createGroup (payload) {
    return this.post('/user', payload)
  }

  update (id, payload) {
    return this.patch(`/user/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/user/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/user?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default UserProvider

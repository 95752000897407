<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add User
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="UserAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="user.code"
            name="code"
            label="User Code"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.firstName"
            name="FirstName"
            label="First name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.lastName"
            name="LastName"
            label="Last name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.email"
            name="Email"
            label="Email"
            :rules="{ required: true, email: true }"
            trim />
          <TextFieldInput
            v-model="user.tel"
            name="Tel"
            label="Tel"
            :rules="{ required: true }"
            trim
          />
          <!-- <TextFieldInput
            v-model="user.username"
            name="Username"
            label="Username"
            :rules="{ required: true }"
            trim /> -->
          <PasswordInput
            v-model="user.password"
            name="Password"
            label="Password"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="user.licenseCode"
            name="License"
            label="License Code"
            trim />
          <SelectField
            v-model="user.userTeamId"
            :options="userTeam"
            :rules="{ required: true }"
            name="UserTeamId"
            form-label="User Team"
            label-option="name"
            return-value="id" />
          <SelectField
            v-model="user.role"
            :options="roleData"
            :rules="{ required: true }"
            name="Role"
            form-label="Role"
            label-option="label"
            return-value="value" />
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import PasswordInput from '@/components/Form/PasswordInput.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    PasswordInput
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    userTeam: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      initialUser: {
        code: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        username: '',
        password: '',
        licenseCode: '',
        userTeamId: null,
        userGroupId: null,
        role: []
      },
      user: {
        code: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        username: '',
        password: '',
        licenseCode: '',
        userTeamId: null,
        userGroupId: null,
        role: []
      },
      userData: [],
      roleData: [
        { label: 'Agent', value: 'agent' },
        { label: 'Management', value: 'management' },
        { label: 'Manager', value: 'manager' },
        { label: 'Supervisor', value: 'supervisor' },
        { label: 'SuperAdmin', value: 'super admin' }
      ]
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Add Record Successfully',
      //     icon: 'EditIcon',
      //     variant: 'success',
      //     text: `👋 User ${this.user.firstName} has been added`
      //   }
      // }),
      if (this.userTeam && this.userTeam.length > 0) {
        const findUserTeam = this.userTeam.filter((item) => item.id === this.user.userTeamId)
        if (findUserTeam && findUserTeam.length > 0) {
          this.user.userGroupId = findUserTeam[0].userGroupId
        }
      }
      this.$emit('add-user', this.user)
    },
    resetForm () {
      this.user = { ...this.initialUser }
      this.$refs.UserAddForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>

<template>
  <b-row class="align-items-end">
    <b-col
      sm="12"
      md="3"
      class="mb-50">
      <SearchTextInput
        v-model="searchUserIdValue"
        label="User Code"
        placeholder="Code"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-50">
      <SearchTextInput
        v-model="searchNameValue"
        label="Name"
        placeholder="Full Name"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-50">
      <SearchTextInput
        v-model="searchTelValue"
        label="Tel"
        placeholder="Tel"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-50">
      <SearchTextInput
        v-model="searchEmailValue"
        label="Email"
        placeholder="Email"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <!-- <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchUserName"
        label="Username"
        placeholder="Username"
        @keyup.enter.native="fetchFilter()" />
    </b-col> -->
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <SearchTextInput
        v-model="searchUserTeamValue"
        label="User Team"
        placeholder="Code and Name"
        @keyup.enter.native="fetchFilter()" />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <StatusDropdown
        :status-filter.sync="statusFilterValue"
        @change-status-filter="fetchFilter()"
      />
    </b-col>
    <b-col
      sm="12"
      md="3"
      class="mb-1 mb-sm-0">
      <RoleDropdown
        :role-filter.sync="roleFilterValue"
        @input="fetchFilter()"
      />
    </b-col>
    <b-col
      sm="0"
      md="9"></b-col>
    <b-col
      sm="12"
      md="3"
      class="mt-1">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="w-100"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50" />
        <span class="align-middle">Search</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import StatusDropdown from '@/components/Filter/StatusDropdown.vue'
import RoleDropdown from '@/components/Filter/RoleDropdown.vue'

export default {
  components: {
    SearchTextInput,
    StatusDropdown,
    RoleDropdown
  },
  props: {
    search: {
      type: Object,
      default: () => (
        {
          id: '',
          name: '',
          tel: '',
          email: '',
          userName: '',
          userTeam: ''
        }
      )
    },
    searchUserId: {
      type: String,
      default: ''
    },
    searchUserName: {
      type: String,
      default: ''
    },
    searchUserEmail: {
      type: String,
      default: ''
    },
    searchUserTel: {
      type: String,
      default: ''
    },
    searchName: {
      type: String,
      default: ''
    },
    statusFilter: {
      type: String,
      default: null
    },
    roleFilter: {
      type: String,
      default: ''
    },
    searchUserUserTeam: {
      type: String,
      default: ''
    }
  },
  computed: {
    searchUserIdValue: {
      get () {
        return this.searchUserId
      },
      set (val) {
        this.$emit('update:search-user-id', val)
      }
    },
    searchNameValue: {
      get () {
        return this.searchUserName
      },
      set (val) {
        this.$emit('update:search-user-name', val)
      }
    },
    searchTelValue: {
      get () {
        return this.searchUserTel
      },
      set (val) {
        this.$emit('update:search-user-tel', val)
      }
    },
    searchEmailValue: {
      get () {
        return this.searchUserEmail
      },
      set (val) {
        this.$emit('update:search-user-email', val)
      }
    },
    // searchUserName: {
    //   get () {
    //     return this.search.userName
    //   },
    //   set (val) {
    //     this.$emit('update:search-user-user-name', val)
    //   }
    // },
    searchUserTeamValue: {
      get () {
        return this.searchUserUserTeam
      },
      set (val) {
        this.$emit('update:search-user-user-team', val)
      }
    },
    statusFilterValue: {
      get () {
        return this.statusFilter
      },
      set (val) {
        this.$emit('update:status-filter', val)
      }
    },
    roleFilterValue: {
      get () {
        return this.roleFilter
      },
      set (val) {
        this.$emit('update:role-filter', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
